import { useMemo, useCallback } from 'react'

import classNames from 'classnames'

import { useAppSelector } from '$store/hooks'

import { SelectOption } from '$components/form/fields/formikField/type/FormikFieldType'
import RootSelect from '$components/ui/selects/RootSelect'

import s from './PrizesTabs.module.scss'

interface PrizesTabsInterface {
  prizes: string | undefined
  setPrizes: (str: string) => void
}

interface PrizeTabInterface extends PrizesTabsInterface {
  name: string
}

const PrizeTab: React.FC<PrizeTabInterface> = ({ prizes, setPrizes, name }) => {
  const isActive = useMemo(() => name === prizes, [name, prizes])

  const clickHandler = useCallback(() => {
    setPrizes(name)
  }, [name, setPrizes])

  return (
    <div
      onClick={clickHandler}
      className={classNames(s['prizesTab'], { [s['active']]: isActive })}
    >
      {name}
    </div>
  )
}

const PrizesTabs: React.FC<PrizesTabsInterface> = (props) => {
  const { setPrizes, prizes } = props
  const allDraws = useAppSelector((store) => store.config.rewards.draw?.draws!)

  const dateOption: SelectOption = Object.keys(
    allDraws.reduce(
      (acc, { title }) => ({
        ...acc,
        [title]: title,
      }),
      {},
    ),
  ).map((key) => ({
    label: key,
    value: key,
  }))

  return (
    <div
      className={classNames(
        s['drawTypesList'],
        dateOption.length > 2 && s['no-border'],
      )}
    >
      {dateOption.length > 2 ? (
        <div className={classNames(s['draw-list-wrap'])}>
          <RootSelect
            name="prizes"
            isColored
            isSearchable={false}
            defaultValue={{ label: prizes ?? '', value: prizes ?? '' }}
            options={dateOption}
            onChange={(option: any) => {
              setPrizes(option?.value ?? '')
            }}
          />
        </div>
      ) : (
        dateOption.map(({ label }) => (
          <PrizeTab key={`prizes-tab-${label}`} name={label} {...props} />
        ))
      )}
    </div>
  )
}

export default PrizesTabs
