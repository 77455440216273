import { HTMLAttributes } from 'react'

import classNames from 'classnames'
import Image from 'next/image'

import img1 from './images/1-number.png'
import img2 from './images/2-number.png'
import img3 from './images/3-number.png'

import classes from './RulesCard.module.scss'

export type RulesCardData = {
  title: string
  text: string
  number: '1' | '2' | '3'
}

type RulesCardProps = HTMLAttributes<HTMLDivElement> & RulesCardData

export const RulesCard = ({
  className,
  number,
  text,
  title,
}: RulesCardProps) => {
  let imgForUse

  switch (number) {
    case '1':
      imgForUse = img1
      break
    case '2':
      imgForUse = img2
      break

    case '3':
      imgForUse = img3
      break

    default:
      break
  }

  return (
    <article className={classNames(classes['wrapper'], className)}>
      <div className={classes['header']}>
        <div className={classes['number']}>
          {imgForUse && (
            <Image
              width={42}
              className={classes['image']}
              height={50}
              src={imgForUse}
              alt={`Цифра ${number}`}
            />
          )}
        </div>
        <h3 className={classes['title']}>{title}</h3>
      </div>
      <p
        className={classes['body']}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </article>
  )
}
