import Image from 'next/image'

import { useAppSelector } from '$store/hooks'

import Anchor from '$components/helpers/Anchor/Anchor'
import Winners from '$components/services/Winners/Winners'

import presentImage from './images/winners-present.webp'
import sausagesImage from './images/winners-sausages.webp'
import sunImage from './images/winners-sun.webp'

import s from './WinnersBlock.module.scss'

const WinnersBlock: React.FC<{
  hashId: string
}> = ({ hashId }) => {
  const draw = useAppSelector((store) => store.config.rewards.draw?.draws)

  return draw?.length ? (
    <div className={s['wrapper']}>
      <div className={s['inner-wrapper']}>
        <Anchor id={hashId} />
        <h2 id="winners" className={s['title']}>
          Победители
        </h2>
        <Winners drawSelectionFormat="select" />
      </div>
      <div className={s['sun']}>
        <Image fill src={sunImage} alt="декор солнце" />
      </div>
      <div className={s['sausages']}>
        <Image fill src={sausagesImage} alt="декор сосиски" />
      </div>
      <div className={s['present']}>
        <Image fill src={presentImage} alt="декор подарок" />
      </div>
    </div>
  ) : null
}

export default WinnersBlock
