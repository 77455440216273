import Image from 'next/image'

import { useAppSelector } from '$store/hooks'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import FaqBlock from '$components/blocks/FaqBlock/FaqBlock'

import SectionWrapper from './_wrapper/SectionWrapper'
import sausagesImage from './faqImages/sasusages.svg'
import sausageImage from './faqImages/sausage.png'
import sunImage from './faqImages/sun.png'

import s from './FaqSection.module.scss'

const FaqSection: React.FC = () => {
  const viewTemplate = useAppSelector((store) => store.config.faq!.viewTemplate)
  const dictionary = useLanguageDictionary()

  return (
    <div className={s['main-wrapper']}>
      <SectionWrapper
        headerType={viewTemplate}
        headerText={dictionary.header.faq}
        hashId="faq"
        sectionClassNames={{ wrapper: s['wrapper'], header: s['title'] }}
      >
        <FaqBlock />
      </SectionWrapper>
      <div className={s['decor-sausages']}>
        <Image fill alt="декор - сосиски" src={sausagesImage} />
      </div>
      <div className={s['decor-sausage']}>
        <Image fill alt="декор - колбаса" src={sausageImage} />
      </div>
      <div className={s['decor-sun']}>
        <Image fill alt="декор - солнце" src={sunImage} />
      </div>
    </div>
  )
}

export default FaqSection
