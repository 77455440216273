import React from 'react'

import { connect, ConnectedProps } from 'react-redux'

import { showPopup } from '$store/slices/popup'
import { PopupConfig } from '$store/slices/popup/types'
import { AppDispatch, RootState } from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'
import usePromoStatus from '$hooks/usePromoStatus/usePromoStatus'
import useUnAuthRedirect from '$hooks/useUnAuthRedirect/useUnAuthRedirect'

import Button from '../../ui/buttons/RootButton/Button'

const UploadCheckButton: React.FC<Props & { noOutline?: boolean }> = ({
  isUploadUser,
  check,
  showPopupAction,
  noOutline,
}) => {
  const navigate = useUnAuthRedirect()
  const postPromoHandler = usePromoStatus(true, {
    title: 'Приём чеков окончен',
  })
  const openUploadCheckPopup = () => {
    if (postPromoHandler()) {
      return
    }
    if (!isUploadUser) {
      navigate()
      return
    }
    showPopupAction({ popupId: 'upload_check' })
  }

  const dictionary = useLanguageDictionary()

  if (check) {
    return (
      <Button outline={!noOutline} onClick={openUploadCheckPopup}>
        {dictionary.button.checkUpload}
      </Button>
    )
  }
  return null
}

type Props = ConnectedProps<typeof connector>
const connector = connect(
  (state: RootState) => ({
    isUploadUser: !!state.user,
    promoStatus: state.config.promo.status,
    check: state.config.check,
  }),
  (dispatch: AppDispatch) => ({
    showPopupAction: (config: PopupConfig) => {
      dispatch(showPopup(config))
    },
  }),
)

export default connector(UploadCheckButton)
