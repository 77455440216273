import Image from 'next/image'

import imgDecor from './images/bg-decor.png'
import img from './images/image.png'

import s from './Main.module.scss'

export const Main = () => (
  <div className={s['wrapper']}>
    <div className={s['content']}>
      <h3 className={s['title']}>3 главных приза по:</h3>
      <p className={s['text']}>
        <span className={s['value']}>100 000 ₽</span>
        <span className={s['desc']}>
          <b>на&nbsp;приятные&nbsp;покупки</b>
        </span>
      </p>
    </div>
    <div className={s['image']}>
      <Image
        src={img}
        fill
        alt="Телефон с открытым сайтом Кунгурский покупай - призы получай и выглядывающими из под него купюрами"
      />
    </div>
    <div className={s['image-decor']}>
      <Image src={imgDecor} fill alt="Декор, желтый зигзаг" />
    </div>
  </div>
)
