import React from 'react'

import classNames from 'classnames'

import { useAppSelector } from '$store/hooks'

import FAQ from '$components/services/FAQ/FAQ'

import s from './FaqBlock.module.scss'

const FaqBlock: React.FC = () => {
  // const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null)
  const faqService = useAppSelector((state) => state.config.faq)

  // const { isTablet } = useContext(ViewportContext)

  if (!faqService) {
    return null
  }

  const { faqs, faq_topics } = faqService

  const withCategories = !!faq_topics.length

  // const defaultFaqs = faqs.filter(
  //   ({ topic_id }) => topic_id === activeCategoryId,
  // )

  // const activeFaqs = defaultFaqs.length
  //   ? defaultFaqs
  //   : faqs.filter(
  //       ({ topic_id }) => topic_id === faq_topics[0].id || activeCategoryId,
  //     )

  return (
    <div>
      <div
        className={classNames(s['faq-container'], {
          [s['with-categories']]: withCategories,
        })}
      >
        <div className={s['faq-block']}>
          {/* {isTablet && (
            <FaqCategories
              activeCategories={activeCategoryId}
              setActiveCategories={setActiveCategoryId}
            />
          )} */}
          <FAQ faq={faqs} />
        </div>
        {/* {!isTablet && (
          <FaqCategories
            activeCategories={activeCategoryId}
            setActiveCategories={setActiveCategoryId}
          />
        )} */}
      </div>
    </div>
  )
}

export default FaqBlock
