import { HTMLAttributes } from 'react'

import classNames from 'classnames'

import classes from './BlueBlock.module.scss'

type BlueBlockProps = HTMLAttributes<HTMLDivElement> & {
  topDecorated?: boolean
  bottomDecorated?: boolean
  smallOnMobile?: boolean
}

export const BlueBlock = ({
  bottomDecorated = false,
  topDecorated = false,
  smallOnMobile = false,
  className,
  children,
  ...props
}: BlueBlockProps) => (
  <section
    className={classNames(
      classes['wrapper'],
      topDecorated && classes['top-decor'],
      bottomDecorated && classes['bottom-decor'],
      smallOnMobile && classes['small'],
      className,
    )}
    {...props}
  >
    {children}
  </section>
)
