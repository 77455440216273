import Image from 'next/image'

import img from './images/image.png'

import s from './Weekly.module.scss'

export const Weekly = () => (
  <div className={s['wrapper']}>
    <div className={s['content']}>
      <h3 className={s['title']}>еженедельно</h3>
      <p className={s['text']}>
        <span className={s['value']}>4 000 ₽</span>
        <span className={s['desc']}>
          <b>электронный сертификат в&nbsp;OZON</b>
        </span>
      </p>
    </div>
    <div className={s['image']}>
      <Image
        src={img}
        fill
        alt="Телефон с открытым сайтом Кунгурский покупай - призы получай и выглядывающими из под него купюрами"
      />
    </div>
  </div>
)
