import { FC } from 'react'

import classNames from 'classnames'

import { useAppSelector } from '$store/hooks'

import classes from './Anchor.module.scss'

export interface AnchorProps {
  id?: string
}

const Anchor: FC<AnchorProps> = ({ id }) => {
  const fixedHeader = useAppSelector((store) => store.config.site?.header_fixed)
  return !id ? null : (
    <div
      id={id}
      className={classNames(classes['anchor'], {
        [classes['offset']]: fixedHeader,
      })}
    />
  )
}

export default Anchor
