import React, { useContext } from 'react'

import classNames from 'classnames'
import Image from 'next/image'

import { useAppSelector } from '$store/hooks'

import { ViewportContext } from '$services/ViewportServices/ViewportServices'

import UploadCheckButton from '$components/button/UploadCheckButton/UploadCheckButton'

import MetaTags from '$/other/meta-tags/MetaTags'

import persons from './images/persons.png'
import present from './images/present.svg'
import sausage from './images/sausage.svg'
import sausages from './images/sausages.svg'
import sunMobile from './images/sun-mobile.svg'
import sun from './images/sun.svg'

import classes from './WelcomePromoBlock.module.scss'

const WelcomePromoBlock: React.FC = () => {
  const description = useAppSelector((state) => state.config.promo.description)
  const isShowBlock = useAppSelector(
    (state) => state.config.site?.show_welcome_block,
  )

  const { isMobile } = useContext(ViewportContext)
  const sunSrcToUse = isMobile ? sunMobile : sun

  if (!isShowBlock) {
    return null
  }

  return (
    <>
      <MetaTags title="Главная" description={description} />
      <div className={classes['page']}>
        <div className={classes['wrap']}>
          <div className={classes['content']}>
            <div className={classes['info']}>
              <h1 className={classes['title']}>
                Кунгурский&nbsp;покупай&nbsp;—
                <br />
                призы&nbsp;получай
              </h1>
              <div className={classes['prize-info']}>
                <p className={classes['prize-title']}>
                  3&nbsp;главных приза&nbsp;по:
                </p>
                <p className={classes['prize-sum']}>100 000 ₽</p>
                <p className={classes['prize-text']}>на приятные покупки</p>
              </div>
              <div className={classes['check-upload-button']}>
                <UploadCheckButton noOutline />
              </div>
            </div>
            <div className={classes['images']}>
              <div className={classes['persons']}>
                <div className={classes['present']}>
                  <Image
                    className={classNames(
                      classes['present-img'],
                      classes['img'],
                    )}
                    src={present}
                    alt=""
                    width={143}
                    height={162}
                  />
                </div>
                <Image
                  className={classNames(classes['persons-img'], classes['img'])}
                  src={persons}
                  alt=""
                  width={1076}
                  height={874}
                />
              </div>
              <div className={classes['sun']}>
                <Image
                  className={classNames(classes['sun-img'], classes['img'])}
                  src={sunSrcToUse}
                  alt=""
                  width={117}
                  height={93}
                />
              </div>

              <div className={classes['sausage']}>
                <Image
                  className={classNames(classes['sausage-img'], classes['img'])}
                  src={sausage}
                  alt=""
                  width={83}
                  height={85}
                />
              </div>
              <div className={classes['sausages']}>
                <Image
                  className={classNames(
                    classes['sausages-img'],
                    classes['img'],
                  )}
                  src={sausages}
                  alt=""
                  width={388}
                  height={162}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomePromoBlock
