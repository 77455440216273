import { CSSProperties } from 'react'

import Image from 'next/image'
import { SwiperSlide } from 'swiper/react'

import { useAppSelector } from '$store/hooks'

import DefaultSlider from '$components/layoutParts/DefaultSlider/DefaultSlider'
import { BlueBlock } from '$components/ui/BlueBlock/BlueBlock'

import sausagesImage from './images/sasusages.svg'
import sausageImage from './images/sausage.png'
import sunImage from './images/sun.png'

import s from './ProductsBlock.module.scss'

// const slides: { img: StaticImageData; name: string }[] = [
//   { img: img1, name: 'Вареные колбасы' },
//   { img: img2, name: 'Ветчины' },
//   { img: img3, name: 'Сосиски, сардельки, шпикачки' },
//   { img: img4, name: 'Полукопченые колбасы' },
//   { img: img5, name: 'Варено-копченые колбасы' },
//   { img: img6, name: 'Сырокопченые колбасы' },
//   { img: img7, name: 'Деликатесы' },
//   { img: img8, name: 'Пельмени' },
//   { img: img9, name: 'Консервы' },
// ]

const nextImgStyles: CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  color: 'transparent',
}

export const ProductsBlock = () => {
  const products = useAppSelector((store) => store.config.promo_products)

  if (!products?.promoProductsEnable) {
    return null
  }

  return (
    <BlueBlock topDecorated bottomDecorated className={s['wrapper']}>
      <h2 id="products" className={s['title']}>
        Продукция
      </h2>
      {products.promoProducts && (
        <div className={s['slider']}>
          <DefaultSlider name="productsSlider">
            {products.promoProducts.map(({ image, name }) => (
              <SwiperSlide key={name} className={s['slide']}>
                <a className={s['product']}>
                  <div className={s['image-wrapper']}>
                    <img style={nextImgStyles} src={image} alt={name} />
                  </div>
                </a>
                <div
                  className={s['text']}
                  dangerouslySetInnerHTML={{ __html: name }}
                />
              </SwiperSlide>
            ))}
          </DefaultSlider>
        </div>
      )}
      <div className={s['decor-sausages']}>
        <Image fill alt="декор - сосиски" src={sausagesImage} />
      </div>
      <div className={s['decor-sausages-2']}>
        <Image fill alt="декор - сосиски" src={sausagesImage} />
      </div>
      <div className={s['decor-sausage']}>
        <Image fill alt="декор - колбаса" src={sausageImage} />
      </div>
      <div className={s['decor-sausage-2']}>
        <Image fill alt="декор - колбаса" src={sausageImage} />
      </div>
      <div className={s['decor-sun']}>
        <Image fill alt="декор - солнце" src={sunImage} />
      </div>
    </BlueBlock>
  )
}
