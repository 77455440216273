import { PropsWithChildren } from 'react'

import classNames from 'classnames'

import { SectionWrapperInterface } from './types'

import SectionHeader from '../_headers/SectionHeader'

import s from './style.module.scss'

const SectionWrapper: React.FC<
  PropsWithChildren<SectionWrapperInterface> & {
    sectionClassNames?: {
      wrapper?: string
      header?: string
    }
  }
> = ({ children, hashId, sectionClassNames, ...props }) => (
  <section
    className={classNames(s['page-section'], sectionClassNames?.wrapper)}
  >
    {/* <Anchor id={hashId} /> */}
    <SectionHeader
      {...props}
      id={hashId}
      className={sectionClassNames?.header}
    />
    {children}
  </section>
)

export default SectionWrapper
