import { useAppSelector } from '$store/hooks'
import { WinnerType } from '$store/slices/config/types/draw/drawType'

const useWinners = (date?: Date, drawType?: string): WinnerType[] => {
  const draws = useAppSelector((store) => store.config.rewards.draw?.draws!)

  if (!date) {
    return draws[draws.length - 1].winners
  }

  const sortDraws = draws.filter(({ start, end }) => {
    const startDate = new Date(start)
    const endDate = new Date(end)

    if (
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime()
    ) {
      return true
    }
  })

  if (!drawType) {
    return sortDraws.flatMap(({ winners }) => winners)
  }

  const winners = sortDraws
    .filter(({ title }) => {
      if (title === drawType) {
        return true
      }
    })
    .flatMap(({ winners: innerWinners, prize }) =>
      innerWinners.map((item) => {
        const newItem = { ...item, prizeTitle: prize.title }

        return newItem
      }),
    )
  return winners
}

export default useWinners
