import Image from 'next/image'

import { BlueBlock } from '$components/ui/BlueBlock/BlueBlock'

import { RulesCard, RulesCardData } from './components/RulesCard/RulesCard'
import sausages from './images/sasusages.svg'
import sausage from './images/sausage.png'
import sun from './images/sun.png'

import classes from './HowToParticipateBlock.module.scss'

const data: RulesCardData[] = [
  {
    title: 'Купите',
    text: '<a target="_blank" href="https://kungur-promo.ru/product.pdf"><u>продукцию бренда</u></a> на&nbsp;сумму от&nbsp;100&nbsp;₽ с&nbsp;1&nbsp;мая по&nbsp;30&nbsp;июня&nbsp;2024 года',
    number: '1',
  },
  {
    title: 'Зарегистрируйте ',
    text: 'чек на&nbsp;сайте с&nbsp;1&nbsp;мая по&nbsp;30&nbsp;июня 2024&nbsp;года',
    number: '2',
  },
  {
    title: 'Получите',
    text: 'гарантированный приз и&nbsp;участвуйте в&nbsp;розыгрыше еженедельных и&nbsp;главных призов',
    number: '3',
  },
]

export const HowToParticipateBlock = () => (
  <BlueBlock topDecorated bottomDecorated className={classes['wrapper']}>
    <h2 id="rules" className={classes['title']}>
      Как участвовать
    </h2>
    <div className={classes['rules']}>
      {data.map((item) => (
        <RulesCard key={item.number} {...item} />
      ))}
    </div>
    <div className={classes['decor-sausages']}>
      <Image fill alt="декор - сосиски" src={sausages} />
    </div>
    <div className={classes['decor-sausage']}>
      <Image fill alt="декор - колбаса" src={sausage} />
    </div>
    <div className={classes['decor-sun']}>
      <Image fill alt="декор - солнце" src={sun} />
    </div>
  </BlueBlock>
)
