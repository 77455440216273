import { useMemo, useEffect, useCallback } from 'react'

import classNames from 'classnames'
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs'

import Button from '$components/ui/buttons/RootButton/Button'
import RootInput from '$components/ui/fields/RootInput'

import searchIcon from './search-icon.svg'

import s from './SearchableInput.module.scss'

const SearchableInput: React.FC<{
  debounce?: number
  handler: (val: string) => void
  value: string
  onChangeHandler: (str: string) => void
}> = ({
  debounce = 500,
  handler = () => {},
  value,
  onChangeHandler: setVal,
}) => {
  // const [value, setValue] = useState<string>('')
  const inputStream$: any = useMemo(
    () =>
      new Subject<string>().pipe(
        debounceTime(debounce),
        distinctUntilChanged(),
        map((val: string) => setVal(val), handler(value)),
      ),
    [debounce, handler],
  )

  useEffect(() => {
    inputStream$.subscribe()
  }, [inputStream$])

  // useEffect(() => {
  //   handler(value)
  // }, [handler, value])

  const onChangeHandler = useCallback(
    (newValue: string | number) => {
      inputStream$.next(newValue)
      setVal(String(newValue))
    },
    [inputStream$],
  )

  return (
    <div className={classNames(s['search-input'])}>
      <RootInput
        externalClassNames={{
          wrapper: s['input-wrapper'],
          input: s['input'],
          placeholder: s['placeholder'],
        }}
        value={value}
        placeholder="Поиск по Email"
        onChange={onChangeHandler}
        isClearable={false}
      />
      <div className={classNames(s['search-input__btn'])}>
        <Button
          onClick={() => {
            handler(value)
          }}
          style={{ backgroundImage: `url(${searchIcon})` }}
          className={classNames(s['search-input__btn'])}
        />
      </div>
    </div>
  )
}

export default SearchableInput
