import { NewsContextType } from '$context/NewsContext'

import pbApi from '$api/promobuilding/pbApi'

import { useAppSelector } from '$store/hooks'
import wrapper from '$store/store'

import useLanguageDictionary from '$hooks/useLanguageDictionary/useLanguageDictionary'

import { HowToParticipateBlock } from '$components/blocks/HowToParticipateBlock/HowToParticipateBlock'
import { Prizes } from '$components/blocks/PrizesBlock/Prizes'
import { ProductsBlock } from '$components/blocks/ProductsBlock'
import WelcomePromoBlock from '$components/blocks/WelcomePromoBlock/WelcomePromoBlock'
import WinnersBlock from '$components/blocks/WinnersBlock/WinnersBlock'
import MainContainer from '$components/container/MainContainer/MainContainer'
import PageWrapper from '$components/PageWrapper/PageWrapper'
import FaqSection from '$components/sections/Faq'
import { BlueBlock } from '$components/ui/BlueBlock/BlueBlock'

import { axiosData } from '$utils/axiosData'

import MetaTags from '$/other/meta-tags/MetaTags'

export interface MainPageProps {
  data?: NewsContextType | undefined
}

export default function IndexPage(/* { data }: MainPageProps */) {
  const {
    faq,
    // promo_products: promoProducts,
    // help,
    // stores,
    // reviews,
    // feedback,
    // check,
    // promocode,
  } = useAppSelector((state) => state.config)
  const description = useAppSelector((state) => state.config.promo.description)

  // const isBlockNews = news_blog?.viewTemplate === 'block'
  const isBlockFAQ = faq?.viewTemplate === 'block'
  // const isPromoProduct = promoProducts?.viewTemplate === 'block'
  // const isHelp = help?.viewTemplate === 'block'
  // const isStores = stores?.viewTemplate === 'block'
  // const isReview = reviews?.viewTemplate === 'block'
  // const isFeedback = feedback?.viewTemplate === 'block'

  const dictionary = useLanguageDictionary()

  return (
    <>
      <MetaTags title={dictionary.header.main} description={description} />
      <WelcomePromoBlock />
      <HowToParticipateBlock />
      <Prizes />
      <ProductsBlock />
      <WinnersBlock hashId="" />
      {isBlockFAQ && (
        <BlueBlock topDecorated smallOnMobile>
          <FaqSection />
        </BlueBlock>
      )}
      <MainContainer>
        <PageWrapper>
          {/* 
        {promocode && <PromoCodeUploadSection />}
        {check && <PromoCheckUploadSection />}
        {isPromoProduct && <PromoProductSection />}
        {isFeedback && <FeedbackSection />}
        {isHelp && <HelpSection />}
        {!!data && <NewsSection data={data} />}
        {isStores && <WhereBuySection />}
        {isReview && <ReviewsSection />} */}
        </PageWrapper>
      </MainContainer>
    </>
  )
}

export const getServerSideProps = wrapper.getServerSideProps(
  ({ getState }) =>
    async (): Promise<{ props: MainPageProps }> => {
      const { viewTemplate } = getState().config.news_blog ?? {}
      const { status } = getState().config.promo ?? {}

      const notNews = status === 'placeholder' || status === 'postpromo'

      if (viewTemplate !== 'block' || notNews) {
        return {
          props: {},
        }
      }

      try {
        const [news = [], categories = [], tags = []] = await Promise.all([
          axiosData(pbApi.getNews()),
          axiosData(pbApi.getCategories()),
          axiosData(pbApi.getTags()),
        ])
        return {
          props: {
            data: {
              news,
              categories,
              tags,
            },
          },
        }
      } catch {
        return {
          props: {
            data: {
              news: [],
              categories: [],
              tags: [],
            },
          },
        }
      }
    },
)
