import UploadCheckButton from '$components/button/UploadCheckButton/UploadCheckButton'

import { Guaranteed } from './components/Guaranteed/Guaranteed'
import { Main } from './components/Main/Main'
import { Weekly } from './components/Weekly/Weekly'

import s from './Prizes.module.scss'

export const Prizes = () => (
  <div className={s['wrapper']}>
    <h2 id="prizes" className={s['title']}>
      Призы
    </h2>
    <div className={s['content']}>
      <div className={s['left-column']}>
        <Guaranteed />
        <Weekly />
      </div>
      <div className={s['right-column']}>
        <Main />
      </div>
    </div>
    <div className={s['check-button']}>
      <UploadCheckButton noOutline />
    </div>
  </div>
)
