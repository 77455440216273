import { useCallback } from 'react'

import pbApi from '$api/promobuilding/pbApi'

import { WinnerType } from '$store/slices/config/types/rewards'

import SearchableInput from '$components/form/fields/basicInput/SearchableInput/SearchableInput'

import s from './Winners.module.scss'

type SearchPanelProps = {
  setWinners: (newWinners: WinnerType[] | null) => void
  resetWinners: () => void
  value: string
  onChangeHandler: (str: string) => void
}

const SearchPanel: React.FC<SearchPanelProps> = ({
  setWinners,
  resetWinners,
  value,
  onChangeHandler,
}) => {
  const handler = useCallback((val: string) => {
    if (val) {
      pbApi
        .searchWinners(val)
        .then((res) => {
          const { data } = res

          setWinners(data.length ? data : null)
        })
        .catch((err) => {
          console.log(err)
          setWinners(null)
        })
    } else {
      resetWinners()
    }
  }, [])

  return (
    <div className={s['search-panel-wrapper']}>
      <SearchableInput
        value={value}
        onChangeHandler={onChangeHandler}
        debounce={1000}
        handler={handler}
      />
    </div>
  )
}

export default SearchPanel
