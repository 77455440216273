import Image from 'next/image'

import img from './images/image.png'

import s from './Guaranteed.module.scss'

export const Guaranteed = () => (
  <div className={s['wrapper']}>
    <div className={s['content']}>
      <h3 className={s['title']}>гарантированно</h3>
      <p className={s['text']}>
        <span className={s['value']}>30 ₽</span>
        <span className={s['desc']}>
          <b>на телефон</b> за&nbsp;10&nbsp;первых принятых&nbsp;чеков
        </span>
      </p>
    </div>
    <div className={s['image']}>
      <Image
        src={img}
        fill
        alt="Телефон с открытым сайтом Кунгурский покупай - призы получай и выглядывающими из под него купюрами"
      />
    </div>
  </div>
)
