import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  PropsWithChildren,
} from 'react'

import classNames from 'classnames'

// STYLE

import { decode } from 'html-entities'

import FAQPointer from './pointer/pointer'

type AccordionItemType = {
  id: number
  activeIndex: number
  title: string
  content?: string
  isHTMLInsert?: boolean
  setActiveIndex: Dispatch<SetStateAction<number>>
}

const titlesm = classNames('faq-title')
const faqItemIcon = classNames('faq-item__icon')
const AccordionItem: React.FC<PropsWithChildren<AccordionItemType>> = ({
  activeIndex,
  title,
  isHTMLInsert,
  content,
  setActiveIndex,
  children,
  id,
}) => {
  const isActive = id === activeIndex
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current!.style.maxHeight = `${
      isActive ? ref.current!.scrollHeight + 20 : 0
    }px`
  }, [isActive])

  const openFaqItem = useCallback(() => {
    setActiveIndex(isActive ? -1 : id)
  }, [id, isActive, setActiveIndex])

  const faqItemQuestionVisibleClasses = useMemo(
    () =>
      classNames('faq-item__question', {
        'faq-item__question_visible': isActive,
      }),
    [isActive],
  )
  const faqItemAnswerVisibleClasses = useMemo(
    () =>
      classNames('faq-item__answer', {
        'faq-item__answer_visible': isActive,
      }),
    [isActive],
  )
  const faqItem = classNames('faq-item', { 'faq-item_active': isActive }, [
    isActive,
  ])

  return (
    <div onClick={openFaqItem} className={faqItem}>
      <button className={faqItemQuestionVisibleClasses}>
        <span className={titlesm}>{decode(title)}</span>
        <div className={faqItemIcon}>
          <FAQPointer />
        </div>
      </button>
      {isHTMLInsert ? (
        <div
          ref={ref}
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={faqItemAnswerVisibleClasses}
          dangerouslySetInnerHTML={{ __html: content ?? '' }}
        />
      ) : (
        <div
          ref={ref}
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={faqItemAnswerVisibleClasses}
        >
          {children ?? content}
        </div>
      )}
    </div>
  )
}

export default AccordionItem
