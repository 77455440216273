import classNames from 'classnames'

import { SectionHeaderInterface } from './types'

import s from './style.module.scss'

const SectionHeader: React.FC<
  SectionHeaderInterface & {
    className?: string
    id?: string
  }
> = ({ headerText, headerType, className, id }) => {
  const H = headerType === 'block' ? 'h2' : 'h1'
  return (
    <H id={id} className={classNames(s['header'], className)}>
      {headerText}
    </H>
  )
}

export default SectionHeader
