import { useEffect, useState } from 'react'

import classNames from 'classnames'
import SimpleBar from 'simplebar-react'

import { useAppSelector } from '$store/hooks'
import { WinnerType } from '$store/slices/config/types/rewards'

import useWinners from '$hooks/useWinners/useWinners'

import DateTimePickerFieldNotFormik from '$components/form/fields/basicInput/DateTimePickerNotFormik/DateTimePicker'
import { SelectOption } from '$components/form/fields/formikField/type/FormikFieldType'
import RootSelect from '$components/ui/selects/RootSelect'

import PrizesTabs from './PrizesTabs/PrizersTabs'
import SearchPanel from './SearchPanel'

import s from './Winners.module.scss'

interface WinnerServiceInterface {
  drawSelectionFormat: 'select' | 'datapicker'
}

const WEEK_DRAW_NAME = 'Еженедельный розыгрыш'

const Winners: React.FC<WinnerServiceInterface> = ({ drawSelectionFormat }) => {
  const { draws } = useAppSelector((store) => store.config.rewards.draw!)
  const [searchValue, setSearchValue] = useState<string>('')
  const drawTitles = Object.keys(
    draws.reduce(
      (acc, { title }) => ({
        ...acc,
        [title]: title,
      }),
      {},
    ),
  )

  const [draw, setDraw] = useState<string>(
    () =>
      drawTitles.find((drawName) => drawName === WEEK_DRAW_NAME) ??
      drawTitles[0],
  )
  const dateOption: SelectOption = draws
    .filter(({ title }) => title === draw)
    .map(({ end }, index) => ({
      label: `${index + 1} неделя`,
      value: new Date(end),
    }))
  const [date, setDate] = useState<Date>(dateOption[0].value)

  const [currSelectValue, setCurrSelectValue] = useState<{
    label: string
    value: any
  } | null>(dateOption[0])

  const handleSetDraw = (drawName: string) => {
    setDraw(drawName)
    setCurrSelectValue(null)
  }

  const prevWinners = useWinners(date, draw)

  const [winners, setWinners] = useState<WinnerType[] | null>(prevWinners)

  useEffect(() => {
    setWinners(prevWinners)
    setSearchValue('')
  }, [draw, date])

  const setNewWinners = (winners: WinnerType[] | null) => {
    setWinners(winners)
  }

  const resetWinners = () => {
    setWinners(prevWinners)
  }

  if (!draws.length) {
    return <p style={{ textAlign: 'center' }}>Розыгрыш еще не состоялся</p>
  }

  return (
    <div className={classNames(s['winners'])}>
      <div className={classNames(s['winners__toolsBar'])}>
        <div className={classNames(s['filters'])}>
          <SearchPanel
            value={searchValue}
            onChangeHandler={(str: string) => setSearchValue(str)}
            setWinners={setNewWinners}
            resetWinners={resetWinners}
          />
        </div>
        <div className={s['right-column']}>
          <PrizesTabs prizes={draw} setPrizes={handleSetDraw} />
          <div className={s['date']}>
            {drawSelectionFormat === 'select' && (
              <RootSelect
                name="draw-type"
                isColored
                options={dateOption}
                isSearchable={false}
                value={currSelectValue}
                placeholder="Неделя розыгрыша"
                onChange={(option: any) => {
                  setDate(option?.value)
                  setCurrSelectValue(option)
                }}
              />
            )}
            {drawSelectionFormat === 'datapicker' && (
              <div style={{ position: 'relative' }}>
                <DateTimePickerFieldNotFormik
                  format="date"
                  dateLabel="Дата"
                  maxDate={
                    new Date(draws.find(({ title }) => title === draw)!.end) ??
                    undefined
                  }
                  minDate={
                    new Date(
                      draws.find(({ title }) => title === draw)!.start,
                    ) ?? undefined
                  }
                  onChange={(newDate) => {
                    const parseDate = new Date(newDate)
                    setDate(parseDate)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SimpleBar className={s['simplebar-wrapper']}>
        <div className={classNames(s['winners-table'])}>
          {winners === null && (
            <div style={{ padding: '100px 30px', textAlign: 'center' }}>
              Победитель с таким Email не найден
            </div>
          )}
          {winners && !!winners.length && (
            <table cellSpacing={0} className={s['table']}>
              <thead>
                <tr>
                  {winners[0].name && <th>Имя</th>}
                  {winners[0].email && <th>Email</th>}
                  <th>Приз</th>
                </tr>
              </thead>
              <tbody>
                {winners.map(({ email, name, prizeTitle }) => (
                  <tr key={`winner-${name}-${email}`}>
                    {!!name && <td>{name}</td>}
                    {!!email && <td>{email}</td>}
                    {!!prizeTitle && <td>{prizeTitle}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {winners && !winners.length && (
            <div style={{ padding: '100px 30px', textAlign: 'center' }}>
              Розыгрыш еще не проводился.
            </div>
          )}
        </div>
      </SimpleBar>
    </div>
  )
}

export default Winners
