import React, { PropsWithChildren, useContext } from 'react'

import classNames from 'classnames'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper } from 'swiper/react'

import { ViewportContext } from '$services/ViewportServices/ViewportServices'

export interface SliderWhereBuyContent {
  img: string
  link: string
  id: number
}

// const NavigationSliderPointer = () => (
//   <svg
//     width="12"
//     height="14"
//     viewBox="0 0 12 14"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M10.7998 1C8.83369 2.46956 6.86992 3.94552 4.77772 5.25203C3.66018 5.94989 2.4763 6.48691 1.32676 7.12195C0.933077 7.33943 1.56501 7.44143 1.73677 7.52033C2.72452 7.97399 3.69059 8.48734 4.6624 8.97154C5.90685 9.59159 7.35729 10.3689 8.32263 11.3618C8.76647 11.8183 9.13322 12.386 9.26225 13"
//       stroke="#AA182C"
//       stroke-width="2"
//       strokeLinecap="round"
//     />
//   </svg>
// )

const paginationConfig = {
  clickable: true,
  renderBullet: (_index: number, className: string) =>
    `<button class="${className}"></button>`,
}

const DefaultSlider: React.FC<PropsWithChildren<{ name: string | number }>> = ({
  children,
  name,
}) => {
  const { isMobile, isTablet } = useContext(ViewportContext)
  const slides = children!.valueOf() as any[]
  const countSlides = slides.length

  if (!slides.length) {
    return null
  }

  return (
    <div className="promo-product-slider">
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        pagination={paginationConfig}
        // https://github.com/nolimits4web/swiper/issues/5784
        slidesPerView="auto"
        navigation={{
          prevEl: `.navigation-button-prev_${name}`,
          nextEl: `.navigation-button-next_${name}`,
        }}
        loop={
          countSlides >= 4 ||
          (isTablet && countSlides >= 3) ||
          (isMobile && countSlides >= 2)
        }
        centerInsufficientSlides={countSlides < 3}
        breakpoints={{
          // https://github.com/nolimits4web/swiper/issues/5784
          320: {
            slidesPerView: 1,
            pagination: {
              ...paginationConfig,
              enabled: countSlides >= 2,
            },
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 18,
            pagination: {
              ...paginationConfig,
              enabled: countSlides >= 3,
            },
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 18,
            pagination: {
              ...paginationConfig,
              enabled: countSlides >= 4,
            },
          },
        }}
        autoHeight={false}
        autoplay={{
          delay: 3000,
        }}
      >
        {children}
      </Swiper>
      <div className={classNames('navigation', `count-slider-${countSlides}`)}>
        <button
          type="button"
          className={`navigation-button-prev_${name} navigation-prev`}
        >
          {/* <NavigationSliderPointer /> */}
        </button>
        <button
          type="button"
          className={`navigation-button-next_${name} navigation-next`}
        >
          {/* <NavigationSliderPointer /> */}
        </button>
      </div>
    </div>
  )
}

export default DefaultSlider
