import React from 'react'

const FAQPointer: React.FC = () => (
  // <svg
  //   width="28"
  //   height="28"
  //   viewBox="0 0 28 28"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path d="M15.1652 12.9448L15.1809 1.2367C15.1618 0.985945 15.0488 0.751876 14.8645 0.581265C14.6801 0.410653 14.438 0.316063 14.1865 0.3164C13.9349 0.316738 13.6925 0.411974 13.5077 0.583081C13.3229 0.754188 13.2093 0.98856 13.1895 1.23936L13.1739 12.9475L1.45865 12.9561C1.19271 12.9565 0.937514 13.0625 0.74921 13.2508C0.560907 13.4391 0.454919 13.6943 0.454563 13.9602C0.454206 14.2262 0.559511 14.4811 0.747311 14.6689C0.935112 14.8567 1.19002 14.962 1.45597 14.9616L13.1712 14.9389L13.1484 26.6541C13.1376 26.7914 13.1553 26.9293 13.2004 27.0592C13.2454 27.1891 13.3169 27.3083 13.4102 27.4092C13.5036 27.5101 13.6168 27.5905 13.7429 27.6454C13.8689 27.7004 14.0051 27.7287 14.1427 27.7285C14.2803 27.7283 14.4165 27.6997 14.5427 27.6444C14.6689 27.5891 14.7824 27.5083 14.876 27.4072C14.9696 27.3061 15.0414 27.1867 15.0868 27.0567C15.1322 26.9266 15.1503 26.7887 15.1398 26.6514L15.1626 14.9362L26.8707 14.9205C27.1215 14.9008 27.3559 14.7872 27.527 14.6024C27.6981 14.4175 27.7933 14.1752 27.7937 13.9236C27.794 13.6721 27.6994 13.43 27.5288 13.2456C27.3582 13.0612 27.1241 12.9482 26.8734 12.9292L15.1652 12.9448Z" />
  // </svg>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="15"
      cy="15"
      rx="15"
      ry="15"
      transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 30 30)"
      fill="white"
    />
    <path
      d="M22 18C20.2855 16.7712 18.5636 15.5438 17.0393 14.2362C16.2251 13.5377 15.5986 12.7978 14.8577 12.0793C14.604 11.8333 14.485 12.2283 14.393 12.3356C13.8637 12.9529 13.2648 13.5567 12.6999 14.1641C11.9765 14.9419 11.0696 15.8484 9.91125 16.4518C9.37866 16.7292 8.71636 16.9584 8 17.039"
      stroke="#16A2B3"
      stroke-width="2"
      strokeLinecap="round"
    />
  </svg>
)

export default FAQPointer
